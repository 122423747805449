
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/devicemanage";
@Options({})
export default class extends Vue {
  popupShow = false;
  queryValue = "";
  paramObj = {
    pageSize: 10,
    pageNum: 1,
    deviceTypeList: [0],
    status: 2,
    name: "",
  };
  deviceNameList = [
    { name: "摄像头" },
    { name: "环境监测" },
    { name: "人脸识别" },
    { name: "车辆道闸" },
    { name: "降尘" },
  ];
  refreshing = false;
  loading = false;
  finished = false;
  deviceList: any[] = [];
  deviceTypeList = [
    { name: "全部", id: 0 },
    { name: "摄像头", id: 1 },
    { name: "环境监测", id: 2 },
    { name: "人脸识别", id: 3 },
    { name: "车辆道闸", id: 4 },
    { name: "降尘", id: 5 },
  ];
  statusList = [
    { name: "全部", id: 2 },
    { name: "不在线", id: 0 },
    { name: "在线", id: 1 },
  ];

  isSelectDevice(id: number) {
    return this.paramObj.deviceTypeList.includes(id);
  }
  fDeviceTypeTagClick(ployload: any) {
    if (ployload.id === 0) {
      this.paramObj.deviceTypeList = [0];
    } else {
      if (this.paramObj.deviceTypeList.indexOf(0) !== -1) {
        this.paramObj.deviceTypeList.splice(
          this.paramObj.deviceTypeList.indexOf(0),
          1
        );
      }
      if (this.paramObj.deviceTypeList.includes(ployload.id)) {
        let arr: Set<number> = new Set(this.paramObj.deviceTypeList);
        arr.delete(ployload.id);
        this.paramObj.deviceTypeList = Array.from(arr);
      } else {
        this.paramObj.deviceTypeList.push(ployload.id);
      }
    }
  }
  fDeviceStatusTagClick(ployload: any) {
    this.paramObj.status = ployload.id;
  }

  fResetBtn() {
    this.paramObj.deviceTypeList = [0];
    this.paramObj.status = 2;
  }
  fSureBtn() {
    this.popupShow = false;
    this.refreshing = true;
    this.onRefresh();
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false;

    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
  onLoad() {
    // console.log("进来了");
    setTimeout(async () => {
      if (this.refreshing) {
        this.paramObj.pageNum = 1;
        this.deviceList = [];
        this.refreshing = false;
      }
      const res: any = await api.deviceList({
        projectId: this.$route.query.projectId
          ? parseInt(this.$route.query.projectId + "")
          : this.$store.state.project.projectinfo.projectId,
        ...this.paramObj,
      });
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.deviceList = [...this.deviceList, ...res.data.data];
      this.paramObj.pageNum++;
      this.loading = false;
      if (this.deviceList.length >= res.data.totalCount) {
        this.finished = true;
      }
    }, 1000);
  }
  fSearch(val: string) {
    this.refreshing = true;
    this.onRefresh();
  }
}
